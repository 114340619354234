import React from "react";

function Subnav(props) {
    return (
      <div className="subnav-bar">
        <div className="section-container">
          <div className="d-flex links">
            {props.content}
          </div>
        </div>
      </div>
    )
}

export default Subnav;