import React from "react";

function Banner(props) {
  return (
    <div className={`banner-link-section ${props.background}`}>
      <div className="section-container">
        {props.content}
      </div>
    </div>
  );
}

export default Banner;