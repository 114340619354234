import React, { useState } from "react";
import Hero from "../components/Hero";
import Subnav from "../components/Subnav-bar";
import Banner from "../components/Banner";
import { Button } from "react-bootstrap";
import highfive from "../assets/images/high-five.png";
import team from "../assets/images/pic-join-our-team.jpeg";
import glassdoor from "../assets/images/GlassdoorLogo.png";
import benefits from "../assets/images/Benefits.png";
import compensation from "../assets/images/Compensation.png";
import education from "../assets/images/Education.png";
import flexibility from "../assets/images/Flexibility.png";
import refreshments from "../assets/images/Refreshments.png";
import activities from "../assets/images/Activities.png";

function Careers() {
  const careersHeroContent = (
    <h1 className="careers-hero-title">
      Grow <b className="hero-bold">Great</b> With Us
    </h1>
  );

  const careersSubnavContent = (
    <>
      <a href="#overview">Overview</a>
      <a href="#join-our-team">Careers</a>
      <a href="#benefits">Benefits</a>
    </>
  );

  const [youtubeCareerSection] = useState('xZ144FdVpB4');

  const bannerContent = (
    <Button variant="banner-section" href="https://lmsonline.com/culture/" style={{color: '#fff'}}>Learn about our culture</Button>
  );

  return (
    <>
      <Hero content={careersHeroContent} background="careers-hero" />
      <Subnav content={careersSubnavContent} />

      <div id="overview" className="two-columns-section careers-section">
        <div className="section-container text-center">
          <h2>Careers</h2>
          <div className="d-lg-flex justify-content-between align-items-center">
            <a href={`https://youtube.com/embed/${youtubeCareerSection}?autoplay=0`} data-fancybox className="video-popup">
              <img src={highfive} alt="two people giving each other a high five"/>
            </a>
            <div className="text-area">
              <h3>Celebrate your strength</h3>
              <p>
                We believe in people over profits and opportunity over red tape. No matter where you came from, LMS is where you can celebrate your abilities and grow. There are always new opportunities for team members to learn vital skills and join innovative projects.
                <br/>
                <br/>
                Everyone is encouraged to think outside the box, work outside the box, and create a fit that works best for all of us. Our team is committed to building solutions for our partners and fostering greatness within one another.
              </p>
            </div>
          </div>
        </div> 
      </div>

      <div className="join-our-team-section two-columns-section" id="join-our-team">
        <div className="section-container text-center">
          <div className="d-lg-flex justify-content-between align-items-center">
            <div className="text-area">
              <h3>Join our team</h3>
              <p>A great place to work isn’t something that just happens.</p>
              <p>It is made up of the common values we share, the genuine support we provide each other, and the vision we continuously work towards, both as individuals and as a team. That is why we take pride in offering comprehensive programs, perks, and resources to support your individual lifestyle and well-being.</p>
              <Button variant="job-openings" href="https://lms78.applytojob.com/apply/" style={{color: '#fff'}}>See All Job Openings </Button>
            </div>
            <img src={team} alt="grid showing employees at work"/>
          </div>
        </div>
      </div>

      <div className="we-offer-section text-center" id="benefits">
        <div className="section-container">
          <div className="text-block">
            <h3 className="has-line"><span>Company benefits</span></h3>
            <div className="ico-block">
              <div className="ico-row d-sm-flex flex-wrap align-items-center">
                <div className="col-sm-4">
                  <div className="ico-text-item">
                    <img className="ico-image" src={benefits} alt=""/>
                    <strong className="ico-title">BENEFITS</strong>
                    <p>We not only invest in your career but in your health and your future too. </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="ico-text-item">
                    <img className="ico-image" src={compensation} alt=""/>
                    <strong className="ico-title">COMPENSATION</strong>
                    <p>Receive compensation and rewards that match your experience and efforts.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="ico-text-item">
                    <img className="ico-image" src={education} alt=""/>
                    <strong className="ico-title">EDUCATION</strong>
                    <p>Keep learning while you work through sponsored programs and certifications.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="ico-text-item">
                    <img className="ico-image" src={flexibility} alt=""/>
                    <strong className="ico-title">FLEXIBILITY</strong>
                    <p>We care more about the quality of your work than where you are when it’s completed.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="ico-text-item">
                    <img className="ico-image" src={refreshments} alt=""/>
                    <strong className="ico-title">REFRESHMENTS</strong>
                    <p>Gather around our kitchen for a team lunch, a cup of coffee, or a beer to keep focused.</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="ico-text-item">
                    <img className="ico-image" src={activities} alt=""/>
                    <strong className="ico-title">ACTIVITIES</strong>
                    <p>Build relationships through out-of-office lunches and adventures with team members.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Banner content={bannerContent} background="careers-banner"/>

      <div className="glassdoor-testimonials-section">
        <div className="section-container">
          <div className="d-flex flex-column align-items-center">
            <div className="img-area">
              <img src={glassdoor} alt="glassdoor logo"/>
            </div>
            <div className="text-area">
              <blockquote>“Thinking for yourself and asking questions is encouraged here.”</blockquote>
              <a href="https://www.glassdoor.com/Reviews/LMS-Reviews-E976068.htm" target="_blank" rel="noopener noreferrer">See our reviews on glassdoor</a>
            </div>
          </div>
        </div>
      </div>

      <div className="diversity-inclusion-section">
        <div className="section-container">
          <h3>Diversity and Inclusion at LMS</h3>
          <p>A workplace where everyone is accepted and celebrated</p>
          <p>LMS, Inc. is proud to be an equal opportunity employer. Diversity of background, skills, and experiences is a core part of our company culture and we know that each individual on our team is at the heart of our success. We do not discriminate based upon gender identity or expression, race, ethnicity, religion, national origin, age, sex, marital status, physical or mental disability, Veteran status, sexual orientation, and any other category protected by law. We celebrate diversity and are committed to creating an inclusive environment for all team members.</p>
        </div>
      </div>
      
    </>
  );
}

export default Careers;
